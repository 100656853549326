<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout row wrap class="pui-form-layout">
				<v-flex xs0 md10></v-flex>
				<v-flex xs12 md1>
					<v-btn :color="colorbtn" @click="autorefresco()" depressed class="elevation-0" style="margin-top:23px" :key="refreshSelect">
						{{ textoBtn }}
					</v-btn>
				</v-flex>
			</v-layout>
			<br />
			<v-layout row wrap class="pui-form-layout">
				<v-flex xs0 md2></v-flex>
				<v-flex xs12 md8>
					<pui-field-set class="pt-3" :style="getStyleFS" :title="$t('lsptrazaconfronta.fs')">
						<v-layout row wrap>
							<v-flex xs1 v-if="autorefrescoactivo">
								<v-icon>fal fa-sync fa-spin</v-icon>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex xs12 md5>
								<pui-text-field
									:id="`matricula-lsptrazaconfronta`"
									v-model="model.matricula"
									:label="$t('lsptrazaconfronta.matricula')"
									disabled
									toplabel
									maxlength="30"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 md5>
								<pui-text-field
									v-if="model.equipamiento"
									:id="`camionlsp-lsptrazaconfronta`"
									v-model="model.equipamiento.camionlsp"
									:label="$t('lsptrazaconfronta.camionlsp')"
									disabled
									toplabel
									maxlength="30"
								></pui-text-field>
							</v-flex>
							<v-flex xs3 md1>
								<pui-text-field
									:id="`lsp-lsptrazaconfronta`"
									v-model="model.lsp"
									:label="$t('lsptrazaconfronta.lsp')"
									disabled
									toplabel
									maxlength="2"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex xs6 md5>
								<pui-text-field
									v-if="model.equipamiento"
									:id="`consigmerc-lsptrazaconfronta`"
									v-model="model.equipamiento.consigmerc"
									:label="$t('lsptrazaconfronta.consigmerc')"
									disabled
									toplabel
									maxlength="30"
								></pui-text-field>
							</v-flex>
							<!-- CONSIGMERCNIF -->
							<v-flex xs6 md5>
								<pui-text-field
									v-if="model.equipamiento"
									:id="`consigmercnif-lsptrazaconfronta`"
									v-model="model.equipamiento.consigmercnif"
									:label="$t('lsptrazaconfronta.consigmercnif')"
									disabled
									toplabel
									maxlength="17"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex xs12 md10>
								<pui-text-area
									:id="`duas-lsptrazaconfronta`"
									v-model="model.duas"
									:label="$t('lsptrazaconfronta.duas')"
									disabled
									toplabel
									maxlength="300"
									rows="2"
								></pui-text-area>
							</v-flex>
							<v-flex xs12 md2>
								<v-btn
									color="primary"
									@click="verPartidas()"
									depressed
									class="elevation-0"
									style="margin-top:35px"
									:key="refreshSelect"
								>
									{{ textoBtnVerPart }}
								</v-btn>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex xs6 md3>
								<pui-text-field
									v-if="model.equipamiento"
									:id="`codtipoequipamiento-lsptrazaconfronta`"
									v-model="model.equipamiento.codtipoequipamiento"
									:label="$t('lsptrazaconfronta.codtipoequipamiento')"
									disabled
									toplabel
									maxlength="3"
								></pui-text-field>
							</v-flex>
							<v-flex xs6 md5>
								<pui-date-field
									v-if="model.equipamiento"
									:id="`fechalsp-lsptrazaconfronta`"
									v-model="model.equipamiento.fechalsp"
									:label="$t('lsptrazaconfronta.fechalsp')"
									disabled
									toplabel
									time
								></pui-date-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-flex>
				<v-flex xs0 md2></v-flex>
			</v-layout>

			<v-layout row wrap id="PARTIDAS" v-if="pkdocadu != null">
				<v-flex xs0 md1></v-flex>
				<v-flex xs12 md10 v-if="pkdocadu != null" style="max-height: 400px;">
					<!--<pui-field-set class="pt-3" :style="getStyleFS" :title="$t('lsptrazaconfronta.fs')">-->
					<v-layout row wrap>
						<v-flex xs12>
							<div>
								<pui-master-detail
									componentName="partida-grid"
									:parentModelName="modelName"
									:parentPk="pkdocadu"
									:parentPkChildFk="{ iddocumento: 'iddocumento' }"
									:modalDialog="isModalDialog"
									:formDisabled="true"
								></pui-master-detail>
							</div>
						</v-flex>
					</v-layout>
					<!--</pui-field-set>-->
				</v-flex>
				<v-flex xs0 md1></v-flex>
			</v-layout>
			<!-- Page Footer -->

			<pui-form-footer v-if="!isModalDialog">
				<!--
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>-->
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
export default {
	name: 'lsptrazaconfronta-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'lsptrazaconfronta',
			refreshSelect: 0,
			colorbtn: 'primary',
			autorefrescodelay: 5,
			autorefrescoactivo: false,
			refreshTimeout: null,
			iddocaduPartidas: null,
			pkdocadu: null
		};
	},
	methods: {
		afterGetData() {
			/*console.log('AGD' + this.model);
			this.model.equipamiento = {
				camionlsp: '',
				consigmerc: '',
				consigmercnif: '',
				codtipoequipamiento: '',
				fechalsp: null
			};*/
			this.consultarUltimoLSP();
			this.autorefresco();
		},
		consultarUltimoLSP() {
			this.refreshSelect++;
			const params = {};

			const url = this.$store.getters.getModelByName(this.modelName).url.ultimoEnSalida;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.lsptraza.consultarLSP.title');
			this.$puiRequests.getRequest(
				url,
				params,
				response => {
					// Valid response, do code and close modal
					this.model = response.data;
					this.model.lsp = response.data.respuestacombinada;
					if (!response.data.equipamiento) this.model.equipamiento = {};
					this.autorefrescodelay = response.data.delayAutorefresh;
					if (this.pkdocadu != null) {
						if (this.iddocaduPartidas != this.model.iddocumentoultimodua) {
							this.iddocaduPartidas = null;
							this.pkdocadu = null;
						}
					}

					this.refreshSelect++;
				},
				e => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.$puiNotify.error(message, title);
				}
			);
		},
		autorefresco() {
			if (!this.autorefrescoactivo) {
				this.autorefrescoactivo = true;
				this.colorbtn = 'secondary';
				this.refreshAutomatically(this.autorefrescodelay);
			} else {
				this.autorefrescoactivo = false;
				this.colorbtn = 'primary';
				this.stopRefresh();
			}
			this.refreshSelect++;
		},
		refreshAutomatically(seconds) {
			const milliseconds = seconds * 1000;
			this.refreshTimeout = setTimeout(() => {
				this.consultarUltimoLSP();
				//console.log('ref');
				if (location.href.endsWith('/' + this.modelName)) this.refreshAutomatically(seconds);
			}, milliseconds);
		},
		stopRefresh() {
			//console.log('stop');
			clearTimeout(this.refreshTimeout);
		},
		verPartidas() {
			this.iddocaduPartidas = this.model.iddocumentoultimodua;
			var docObjectPk = { iddocumento: this.model.iddocumentoultimodua };
			const docObjectPkStr = JSON.stringify(docObjectPk);
			this.pkdocadu = this.$puiUtils.utf8ToB64(docObjectPkStr);

			//document.getElementById('PARTIDAS').style.width = document.getElementById('PRINCIPAL_LY').width - 10 + 'px';
		}
	},
	computed: {
		textoBtn() {
			if (this.autorefrescoactivo) return this.$t('lsptrazaconfronta.autorefrescoDes');
			return this.$t('lsptrazaconfronta.autorefrescoAct');
		},
		textoBtnVerPart() {
			return 'Partidas';
		},
		getStyleFS() {
			if (this.autorefrescoactivo) return 'padding-left:15px !important;border-color:red';
			return 'padding-left:15px !important;border-color:rgb(233, 236, 237)';
		}
	},
	created() {
		//console.log('CRT' + this.model);
	},
	mounted() {
		//console.log('MNT' + this.model);
	}
};
</script>
